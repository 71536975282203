import initialState from './state'

export default {
  RESET_STORE: (state) => {
    Object.assign(state, initialState())
  },

  SET_AUTH_USER: (state, { authUser }) => {
    if (authUser) {
      const { uid, email, emailVerified } = authUser
      state.authUser = { uid, email, emailVerified }
    }
  },
  SET_CLAIMS: (state, { claims }) => {
    if (claims) {
      state.claims = claims
    }
  },
  SET_USER_DOC: (state, { userDoc }) => {
    if (userDoc) {
      state.userDoc = userDoc
    }
  },
  SET_SUBSCRIPTION_DOC: (state, { subscriptionDoc }) => {
    if (subscriptionDoc) {
      state.subscriptionDoc = subscriptionDoc
    }
  },
  SET_SHOW_UPGRADE_MODAL: (state, showUpgradeModal) => {
    state.showUpgradeModal = showUpgradeModal
  },
}

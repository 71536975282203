export default {
  isLoggedIn: (state) => {
    try {
      return state.authUser.id !== null
    } catch {
      return false
    }
  },
  isAdmin: (state) => {
    try {
      return state.claims.admin === true
    } catch {
      return false
    }
  },
  showUpgradeModal: (state) => {
    try {
      return state.showUpgradeModal
    } catch {
      return false
    }
  },
  isSubscribed: (state) => {
    try {
      if (state.subscriptionDoc) {
        return state.subscriptionDoc.status === 'active' || state.subscriptionDoc.status === 'trialing'
      }
      return state.userDoc.status === 'active' || state.userDoc.status === 'trialing'
    } catch {
      return false
    }
  },
  subscribedTier: (state) => {
    const TIERS = {
      FREE: 'FREE',
      BASIC: 'BASIC',
      PRO: 'PRO',
      BUSINESS: 'BUSINESS',
    }

    try {
      if (state.userDoc.status === 'active' || state.userDoc.status === 'trialing') {
        const planID = state.userDoc?.subscription?.subscription_plan_id || null
        switch (planID) {
          // Basic Monthly
          case '777747':
            return TIERS.BASIC
          // Basic Annual
          case '777748':
            return TIERS.BASIC
          // Pro Monthly
          case '777749':
            return TIERS.PRO
          // Pro Annual
          case '777750':
            return TIERS.PRO
          // Business Monthly
          case '777751':
            return TIERS.BUSINESS
          // Business Annual
          case '777752':
            return TIERS.BUSINESS
          // LEGACY: Echowave Pro Annually
          case '639383':
            return TIERS.PRO
          // LEGACY: Echowave Pro Monthly
          case '639382':
            return TIERS.PRO
          // LEGACY: Echowave Basic Annually
          case '639381':
            return TIERS.BASIC
          // LEGACY: Echowave Basic Monthly
          case '639380':
            return TIERS.BASIC
          // LEGACY: Echowave.io Monthly (before tiers)
          case '628615':
            return TIERS.BASIC
          // LEGACY: [Beta] EchoWave Yearly
          case '592108':
            return TIERS.BASIC
          //  [Beta] EchoWave.io
          case '592096':
            return TIERS.BASIC
          default:
            return TIERS.FREE
        }
      }
      return TIERS.FREE
    } catch (e) {
      return TIERS.FREE
    }
  },
}

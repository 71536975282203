
import SpeedkitImage from '#speedkit/components/SpeedkitImage'

export default {
  name: 'footer-section',
  components: { SpeedkitImage },
  computed: {
    year() {
      const d = new Date()
      return d.getFullYear()
    },
  },
}

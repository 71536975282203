export const state = () => ({
  files: {},
})

export const mutations = {
  addFile(state, file) {
    state.files[file.id] = file
  },
}

export const getters = {
  getSourceByFileId: (state) => (id) => {
    let file = state.files[id]
    if (!file) {
      return null
    }

    if (file.data) {
      var reader = new FileReader()
      return file.data
    }

    return file.file.remoteSource
  },
  getFileById: (state) => (id) => {
    return state.files[id]
  },
}

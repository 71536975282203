import Vue from "vue";

export const state = () => ({})

const mutations = {
  add(state, payload) {
    Vue.set(state, payload.id, payload)
  },
  remove(state, payload) {
    delete state[payload.id]
  },
  update(state, payload) {
    Vue.set(state, payload.id, payload)
  }
}

const getters = {
  getUploadById: (state) => (id) => {
    return state[id]
  },
}

const actions = {
  add({ commit }, payload) {
    commit('add', payload)
  },
  remove({ commit }, payload) {
    commit('remove', payload)
  },
  update({ commit }, payload) {
    commit('update', payload)
  },
}

export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters,
}

export default function ({ store, redirect }) {
  if (!process.server) {
    // If the user is not authenticated
    if (!store.state.authUser) {
      return redirect('/login/')
    }

    // If the user is not an admin
    if (!store.state || !store.state.claims || store.state.claims.admin !== true) {
      return redirect('/app/')
    }
  }
}


import SpeedkitImage from '#speedkit/components/SpeedkitImage'

export default {
  name: 'header-bar',
  components: { SpeedkitImage },
  data() {
    return {
      mobileMenu: false,
      showToolFlyout: false,
      showGuidesFlyout: false,
      showBlogFlyout: false,
      user: null,
    }
  },
  mounted() {
    this.user = this.$fire.auth.currentUser
  },
  methods: {
    toggleGuides() {
      if (this.showGuidesFlyout) {
        this.hideGuides()
      } else {
        this.showGuides()
      }
    },
    showGuides() {
      this.showGuidesFlyout = true
      this.hideTools()
      this.hideBlog()
    },
    hideGuides() {
      this.showGuidesFlyout = false
    },
    toggleTools() {
      if (this.showToolFlyout) {
        this.hideTools()
      } else {
        this.showTools()
      }
    },
    showTools() {
      this.showToolFlyout = true
      this.hideGuides()
      this.hideBlog()
    },
    hideTools() {
      this.showToolFlyout = false
    },
    toggleBlog() {
      if (this.showBlogFlyout) {
        this.hideBlog()
      } else {
        this.showBlog()
      }
    },
    showBlog() {
      this.showBlogFlyout = true
      this.hideGuides()
      this.hideTools()
    },
    hideBlog() {
      this.showBlogFlyout = false
    },
  },
}

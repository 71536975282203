import { cloneDeep } from 'lodash'
import makeId from '~/helpers/makeId'
import { RATIOS } from '~/helpers/ratios'
import Vue from "vue";

export const state = () => ({
  segments: {},
  preview: {},
  resetSegments: {},
  size: {
    ratio: '16:9',
  },
  backgroundColor: '#000000',
  fps: 30,
  title: '',
  uploadInProgress: false,
  uploadProgress: 0,
  type: 'STUDIO',
  duration: 0,
  id: null,
})

const mutations = {
  set: (state, payload) => {
    // update the state for each key
    Object.keys(payload).forEach(key => {
      Vue.set(state, key, payload[key])
    })
  },
  deleteSegment: (state, objectSegmentKey) => {
    delete state.segments[objectSegmentKey]
    state.segments = { ...state.segments }
  }
}

const getters = {
  getSegmentById: (state, getters, rootState, rootGetters) => (id) => {
    if (!state.segments[id]) {
      return null
    }

    const remoteFile = rootGetters['editor/uploads/getUploadById'](state.segments[id].fileId)
    let remoteSource = ''
    if (remoteFile && remoteFile.source) {
      remoteSource = remoteFile.source.includes('http') ? remoteFile.source : `https://echowave.io/bamboo/file/lunar-store/${encodeURIComponent(remoteFile.source)}`
    }


    let parentOverrides = {}
    if(state.segments[id].parentId) {
      const parentSegment = getters.getSegmentById(state.segments[id].parentId)
      parentOverrides.startTime = parentSegment.startTime
      parentOverrides.endTime = parentSegment.endTime
      parentOverrides.cut = parentSegment.cut
      parentOverrides.duration =parentSegment.endTime - parentSegment.startTime
    }

    let overrides = {}

    // if start time is not set, set it to 0
    if (!state.segments[id].startTime) {
      overrides.startTime = 0
    }

    // if end time is not set, set it to duration
    if (!state.segments[id].endTime) {
      overrides.endTime = 10000
    }

    overrides.konvaZIndex = getters.getKonvaZIndex(id)

    return {
      ...state.segments[id],
      duration: state.segments[id].endTime - state.segments[id].startTime,
      file: rootGetters['editor/files/getFileById'](state.segments[id].fileId),
      upload: rootGetters['editor/uploads/getUploadById'](state.segments[id].fileId),
      remoteFile,
      remoteSource,
      ...overrides,
      ...parentOverrides
    }
  },
  allSegments: (state, getters) => {
    const segments = {}

    Object.keys(state.segments).forEach((key) => {
      segments[key] = getters.getSegmentById(key)
    })

    return segments
  },
  getProject: (state) => {
    // TODO use this state in renders dialog preview
    return state
  },
  segmentsCount: (state) => {
    return Object.keys(state.segments).length
  },
  maxZIndex: (state) => {
    return Math.max(...Object.values(state.segments).map((o) => o.zIndex))
  },
  minZIndex: (state) => {
    return Math.min(...Object.values(state.segments).map((o) => o.zIndex))
  },
  getKonvaZIndex: (state) => (id) => {
    const segments = Object.values(state.segments).sort((a, b) => {
      if (a.zIndex === b.zIndex) {
        // ID is only important when zIndex are the same
        return b.id - a.id
      }

      // audio segments always go last
      if (a.type === 'AUDIO') {
        return -1
      }

      return a.zIndex - b.zIndex
    })

    return Math.max(segments.indexOf(state.segments[id]), 0)
  },
  size: (state) => {
    const { size } = state
    let idealRatioWidth, idealRatioHeight, idealWidth
    if (size.ratio === 'original') {
      return {
        width: size.original.width,
        height: size.original.height,
      }
    }

    const ratio = RATIOS[size.ratio]
    idealRatioHeight = ratio.idealRatioHeight
    idealRatioWidth = ratio.idealRatioWidth

    let width = ratio.resolutions.hd
    let height = Math.round((idealRatioHeight / idealRatioWidth) * width)

    return {
      height,
      width,
    }
  },
  userId: (state) => {
    return state.userId
  },
  duration: (state) => {
    return Math.max(...Object.values(state.segments).map((segment) => segment.endTime || 0))
  },
  getLayers: (state) => {
    let layers = [[]]
    let layerMap = {}

    let currentLayer = 0

    const isSafe = (newRange, existingRange) => newRange.startTime >= existingRange.endTime || newRange.endTime <= existingRange.startTime

    const segments = Object.values(state.segments).sort((a, b) => {
      if (a.zIndex === b.zIndex) {
        // ID is only important when zIndex are the same
        return b.id - a.id
      }

      return a.zIndex - b.zIndex
    })

    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i]

      if (layers[currentLayer].length === 0) {
        // No elements in current layer so just add it
        layers[currentLayer].push(segment)
      } else if (layers[currentLayer].every((layerSegment) => isSafe(segment, layerSegment))) {
        // No collisions so add to current layer
        layers[currentLayer].push(segment)
      } else {
        // Create a new layer
        currentLayer++
        layers[currentLayer] = [segment]
      }

      layerMap[segment.id] = currentLayer
    }

    return {
      layers: layers,
      map: layerMap,
    }
  },
}

const actions = {
  set({ commit }, payload) {
    commit('set', payload)
  },
  updateSegment({ dispatch, commit, state }, payload) {
    if (!payload.id) {
      throw new Error('Pass in `id` key when updating a segment')
    }

    let segments = cloneDeep(state.segments)
    segments[payload.id] = {
      ...segments[payload.id],
      ...payload,
    }

    const duration = Math.max(...Object.values(state.segments).map((segment) => segment.endTime))

    dispatch('set', {
      duration,
      segments,
    })
  },
  updateSegmentOptions({ dispatch, commit, state }, payload) {
    if (!payload.id) {
      throw new Error('Pass in `id` key when updating a segment options')
    }

    let segments = cloneDeep(state.segments)

    if (segments[payload.id].options) {
      segments[payload.id].options = {
        ...segments[payload.id].options,
        ...payload.options,
      }
    } else {
      segments[payload.id].options = {
        ...payload.options,
      }
    }

    dispatch('set', {
      segments,
    })
  },
  updateSegmentSubtitles({ dispatch, commit, state }, payload) {
    if (!payload.id) {
      throw new Error('Pass in `id` key when updating a segment options')
    }

    let segments = cloneDeep(state.segments)

    if (segments[payload.id].subtitles) {
      segments[payload.id].subtitles.segments[payload.index] = {
        ...segments[payload.id].subtitles.segments[payload.index],
        ...payload.subtitles,
      }
    }

    dispatch('set', {
      segments,
    })
  },
  deleteSegment({ dispatch, commit, state }, payload) {

    if(state.segments[payload].children && state.segments[payload].children.length > 0){
      state.segments[payload].children.forEach((child) => {
        dispatch('deleteSegment', child)
      })
    }

    if (state.segments[payload].parentId) {
      const parentId = state.segments[payload].parentId
      dispatch('updateSegment', {
        id: parentId,
        children: state.segments[parentId].children.filter((child) => child !== payload),
      })

    }

    commit('deleteSegment', payload)
  },
  newSegment({ dispatch, commit, state }, payload) {
    if(this.$fire && this.$fire.analytics) {
      this.$fire.analytics.logEvent('video_add_segment')
    }
    if (typeof posthog !== 'undefined') {
      posthog.capture('video_add_segment')
    }

    if (!payload.id) {
      throw new Error('Pass in `id` key when updating a segment')
    }

    let segments = cloneDeep(state.segments)
    segments[payload.id] = {
      ...payload,
    }

    const duration = Math.max(...Object.values(segments).map((segment) => segment.endTime))

    dispatch('set', {
      duration,
      segments,
    })

    if (window && window.innerWidth < 768) {
      dispatch('editor/tools/removeActiveToolOnly', null, {root:true})
    }
    const event = new CustomEvent('closeUploadModal')
    document.dispatchEvent(event)

  },
  setPreviewThumbnail({ dispatch, commit }, payload) {
    dispatch('set', {
      preview: {
        thumbnail: payload,
      },
    })
  },
  setRatio({ dispatch, commit }, payload) {
    dispatch('set', {
      size: {
        ratio: payload,
      },
    })
  },
  setId({ dispatch, commit }, payload) {
    dispatch('set', {
      id: payload
    })
  },
  setTitle({ dispatch, commit }, payload) {
    if(this.$fire && this.$fire.analytics) {
      this.$fire.analytics.logEvent('video_set_title')
    }

    dispatch('set', {
      title: payload,
    })
  },
  setUploadProgress({ dispatch, commit }, payload) {
    dispatch('set', {
      uploadInProgress: payload.uploadInProgress,
      uploadProgress: payload.uploadProgress,
    })
  },
  setOriginalSize({ dispatch, commit }, payload) {
    dispatch('set', {
      size: {
        original: {
          width: payload.width,
          height: payload.height,
        },
        ratio: 'original',
      },
    })
  },
  setBackgroundColor({ dispatch, commit }, payload) {
    dispatch('set', {
      backgroundColor: payload,
    })
  },
  async loadFromFirestore({ dispatch, state }) {
    if (!state.id) {
      throw new Error('No video id')
    }

    const video = await this.$fire.firestore.collection("projects").doc(state.id).get()

    if (video.exists) {
      const data = video.data()
      dispatch('set', {
        ...data,
      })
    } else {
      throw new Error('Project does not exist')
    }
  },
  async forceSync({dispatch, state}) {

    if (!state.id) {
      throw new Error('No video id')
    }

    await this.$fire.firestore.collection("projects").doc(state.id).set({
      ...state,
      updated_at: this.$fireModule.firestore.FieldValue.serverTimestamp(),
    })
  },
  duplicateSegment({ dispatch, commit, state }, payload) {
    if (!payload.id) {
      throw new Error('Pass in `id` key to duplicate')
    }

    let newSegmentID = makeId(7)
    let segments = cloneDeep(state.segments)
    segments[newSegmentID] = {
      ...segments[payload.id],
      id: newSegmentID,
    }

    dispatch('set', {
      segments,
    })
  },
  moveSegmentToIndex({ dispatch, commit, state }, payload) {
    if (!payload.id) {
      throw new Error('Pass in `id` key')
    }

    let segments = cloneDeep(state.segments)

    let orderedSegments = [...Object.values(segments)].sort((a, b) => {
      if (a.zIndex === b.zIndex) {
        // ID is only important when zIndex are the same
        return b.id - a.id
      }
      return a.zIndex - b.zIndex
    })

    let currentIndex = orderedSegments.findIndex((x) => x.id === payload.id)

    let referenceSegment = orderedSegments[payload.index - 1]
    let newZIndex = 0

    if (referenceSegment) {
      newZIndex = referenceSegment.zIndex
    } else {
      newZIndex = Math.max(...Object.values(state.segments).map((o) => o.zIndex))
    }

    for (let i = 0; i < orderedSegments.length; i++) {
      if (currentIndex > payload.index) {
        if (i <= payload.index) {
          segments[orderedSegments[i].id].zIndex = i + 1
        }

        if (i > payload.index) {
          segments[orderedSegments[i].id].zIndex = i + 2
        }
      } else {
        if (i <= payload.index) {
          segments[orderedSegments[i].id].zIndex = i
        }

        if (i > payload.index) {
          segments[orderedSegments[i].id].zIndex = i + 2
        }
      }
    }

    if (currentIndex > payload.index) {
      segments[payload.id].zIndex = payload.index
    } else {
      segments[payload.id].zIndex = payload.index + 1
    }

    dispatch('set', {
      segments,
    })
  },
}

export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters,
}

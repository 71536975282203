export default (context) => {
  function loadScript(src, dataset) {
    return new Promise(function (resolve, reject) {
      var s
      s = document.createElement('script')
      s.src = src
      s.onload = resolve
      s.onerror = reject

      if (dataset) {
        dataset.forEach((data) => {
          s.dataset[data.name] = data.value
        })
      }

      document.head.appendChild(s)
    })
  }

  function checkScripts(route) {
    const path = route.fullPath

    window.$crisp = window.$crisp || []
    window.$crisp.push(["safe", true])

    if (path.includes('app') || path.includes('admin') || path.includes('pricing') || path.includes('register') || path === '' || path === '/') {
      loadScript('https://client.crisp.chat/l.js').then(() => {})
    }
  }

  // Every time the route changes (fired on initialization too)
  context.app.router.afterEach((to, from) => {
    checkScripts(to)
  })
}


import SpeedkitLayer from '#speedkit/components/SpeedkitLayer'
import { getStyleDescription } from '#speedkit/utils/description'

export default {
  components: {
    SpeedkitLayer,
  },
  data () {
    return {
      hydrate: false
    };
  },
  head () {
    return this.$speedkit.head({
      noscript: [
        getStyleDescription('#nuxt-speedkit-layer-content { animation-delay: initial !important; } #nuxt-speedkit-layer-content > div { animation-delay: initial !important; }', true)
      ],
      __dangerouslyDisableSanitizers: ['noscript']
    });
  }
}

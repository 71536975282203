export const state = () => ({
  timelineScale: 50,
  currentTimestamp: 0,
  playing: false,
  draggingElement: false,
  guideline: false,
  buffering: [],
  filmstrips: {}
})

export const mutations = {
  setTimestamp(state, timestamp) {
    if (state.currentTimestamp !== timestamp) {
      state.currentTimestamp = timestamp
    }
  },
  setFilmstrip(state, {segmentId, file}) {
    state.filmstrips = {...state.filmstrips, [segmentId]: file }
  },
  setPlaying(state, playing) {
    if (state.playing !== playing) {
      state.playing = playing
    }
  },
  setDraggingElement(state, draggingElement) {
    state.draggingElement = draggingElement
  },
  setGuideline(state, guideline) {
    state.guideline = guideline
  },
  addBuffering(state, segmentId) {
    if (state.buffering.includes(segmentId)) {
      return;
    }

    state.buffering = [...state.buffering, segmentId]
  },
  removeBuffering(state, segmentId) {
    state.buffering = state.buffering.filter(function (value, index, arr) {
      return value !== segmentId
    })
  },
}

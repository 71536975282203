
import speedkitHydrate from '#speedkit/hydrate'
import SpeedKitMessage from "~/components/SpeedKitMessage.vue";

export default {
  components: {
    SpeedKitMessage,
    HeaderBar: speedkitHydrate(() => import('@/components/HeaderBar.vue')),
    FooterSection: speedkitHydrate(() => import('@/components/FooterSection.vue')),
  },
}
